<template>
	<core-section id="who-i-am">
		<!-- <abstract-1 /> -->

		<core-heading>Qui suis-je ?</core-heading>

		<v-col cols="12" md="7">
			<v-row justify="space-between">
				<v-col cols="12">
					<core-text class="mb-0">
						<p v-text="schema.basics.summary" />
						<div>
							<v-img :src="require('@/assets/signature.png')" class="ml-auto" width="150" />
						</div>
					</core-text>
				</v-col>
			</v-row>
		</v-col>

		<v-col class="hidden-sm-and-down text-right text-uppercase" cols="5">
			<div class="display-1" v-text="schema.basics.label" />

			<span class="primary--text headline font-weight-light" v-text="schema.basics.name" />

			<!-- <v-img :src="require('@/assets/john.png')" contain height="900" style="right: 0; position: absolute; top: 300px" /> -->
		</v-col>
	</core-section>
</template>

<script>
	// Utilities
	import { mapState } from "vuex"

	export default {
		name: "WhoIAm",

		components: {
			Abstract1: () => import("@/components/Abstract1"),
		},

		computed: {
			...mapState("app", ["schema"]),
		},
	}
</script>

<style lang="sass">
	.v-expansion-panels > li
	  border-top: 1px solid rgba(#fff, .12) !important
</style>
